// src/Accordion/index.tsx
import { AccordionBlock } from "@achmea/ui";
import { jsx } from "react/jsx-runtime";
var AccordionComponent = ({ fields, rendering }) => /* @__PURE__ */ jsx(AccordionBlock, { fields, rendering });
var Accordion_default = AccordionComponent;

// src/AccordionItem/index.tsx
import { AccordionItem } from "@achmea/ui";
import { jsx as jsx2 } from "react/jsx-runtime";
var AccordionItemComponent = ({ fields }) => {
  return /* @__PURE__ */ jsx2(AccordionItem, { fields });
};
var AccordionItem_default = AccordionItemComponent;

// src/AccordionWithImages/index.tsx
import { AccordionWithImagesBlock } from "@achmea/ui";
import { jsx as jsx3 } from "react/jsx-runtime";
var AccordionWithImages = ({
  fields,
  rendering
}) => {
  return /* @__PURE__ */ jsx3(AccordionWithImagesBlock, { fields, rendering });
};
var AccordionWithImages_default = AccordionWithImages;

// src/AccordionWithImagesItem/index.tsx
import { AccordionWithImagesItem } from "@achmea/ui";
import { jsx as jsx4 } from "react/jsx-runtime";
var AccordionWithImagesItemComponent = ({
  fields
}) => /* @__PURE__ */ jsx4(AccordionWithImagesItem, { fields });
var AccordionWithImagesItem_default = AccordionWithImagesItemComponent;

// src/AccordionWithSideImage/index.tsx
import { AccordionWithSideImageBlock } from "@achmea/ui";
import { jsx as jsx5 } from "react/jsx-runtime";
var AccordionWithSideImageComponent = ({ fields, rendering }) => /* @__PURE__ */ jsx5(AccordionWithSideImageBlock, { fields, rendering });
var AccordionWithSideImage_default = AccordionWithSideImageComponent;

// src/ArticleOverview/index.tsx
import { ArticleOverviewBlock } from "@achmea/ui";
import { jsx as jsx6 } from "react/jsx-runtime";
var ArticleOverviewComponent = ({
  fields,
  rendering
}) => {
  return /* @__PURE__ */ jsx6(ArticleOverviewBlock, { fields, rendering });
};
var ArticleOverview_default = ArticleOverviewComponent;

// src/ArticleOverviewAutomatic/index.tsx
import { ArticleOverviewAutomaticBlock } from "@achmea/ui";
import { jsx as jsx7 } from "react/jsx-runtime";
var ArticleOverviewAutomaticComponent = ({
  fields,
  rendering
}) => {
  return /* @__PURE__ */ jsx7(ArticleOverviewAutomaticBlock, { fields, rendering });
};
var ArticleOverviewAutomatic_default = ArticleOverviewAutomaticComponent;

// src/ArticleOverviewItem/index.tsx
import { ArticleOverviewItem } from "@achmea/ui";
import { jsx as jsx8 } from "react/jsx-runtime";
var ArticleOverviewItemComponent = ({
  fields
}) => {
  return /* @__PURE__ */ jsx8(ArticleOverviewItem, { fields });
};
var ArticleOverviewItem_default = ArticleOverviewItemComponent;

// src/ArticleOverviewPageLayout/index.tsx
import { ArticleOverviewPage } from "@achmea/ui";
import { jsx as jsx9 } from "react/jsx-runtime";
var ArticleOverviewPageLayoutComponent = ({
  rendering
}) => /* @__PURE__ */ jsx9(ArticleOverviewPage, { rendering });
var ArticleOverviewPageLayout_default = ArticleOverviewPageLayoutComponent;

// src/ArticlePageLayout/index.tsx
import { ArticlePage } from "@achmea/ui";
import { jsx as jsx10 } from "react/jsx-runtime";
var ArticlePageLayoutComponent = ({
  rendering
}) => /* @__PURE__ */ jsx10(ArticlePage, { rendering });
var ArticlePageLayout_default = ArticlePageLayoutComponent;

// src/Button/index.tsx
import { Button } from "@achmea/ui";
import { jsx as jsx11 } from "react/jsx-runtime";
var ButtonComponent = ({ fields }) => /* @__PURE__ */ jsx11(Button, { fields });
var Button_default = ButtonComponent;

// src/ButtonContainer/index.tsx
import { ButtonContainer } from "@achmea/ui";
import { jsx as jsx12 } from "react/jsx-runtime";
var ButtonContainerComponent = ({
  rendering,
  placeholderName
}) => /* @__PURE__ */ jsx12(ButtonContainer, { rendering, placeholderName });
var ButtonContainer_default = ButtonContainerComponent;

// src/CallToAction/index.tsx
import { CallToActionBlock } from "@achmea/ui";
import { jsx as jsx13 } from "react/jsx-runtime";
var CallToActionComponent = ({
  fields,
  rendering
}) => /* @__PURE__ */ jsx13(CallToActionBlock, { fields, rendering });
var CallToAction_default = CallToActionComponent;

// src/Card/index.tsx
import { ImageCard } from "@achmea/ui";
import { jsx as jsx14 } from "react/jsx-runtime";
var CardComponent = ({ fields }) => /* @__PURE__ */ jsx14(ImageCard, { fields });
var Card_default = CardComponent;

// src/CardSlider/index.tsx
import { CardSliderBlock } from "@achmea/ui";
import { jsx as jsx15 } from "react/jsx-runtime";
var CardSliderComponent = ({
  fields,
  rendering
}) => /* @__PURE__ */ jsx15(CardSliderBlock, { fields, rendering });
var CardSlider_default = CardSliderComponent;

// src/Carousel/index.tsx
import { CarouselBlock } from "@achmea/ui";
import { jsx as jsx16 } from "react/jsx-runtime";
var CarouselComponent = ({ fields, rendering }) => /* @__PURE__ */ jsx16(CarouselBlock, { fields, rendering });
var Carousel_default = CarouselComponent;

// src/CarouselCardItem/index.tsx
import { CarouselCardItem } from "@achmea/ui";
import { jsx as jsx17 } from "react/jsx-runtime";
var CarouselCardItemComponent = ({
  fields
}) => /* @__PURE__ */ jsx17(CarouselCardItem, { fields });
var CarouselCardItem_default = CarouselCardItemComponent;

// src/CarouselImageItem/index.tsx
import { CarouselImageItem } from "@achmea/ui";
import { jsx as jsx18 } from "react/jsx-runtime";
var CarouselImageItemComponent = ({
  fields
}) => /* @__PURE__ */ jsx18(CarouselImageItem, { fields });
var CarouselImageItem_default = CarouselImageItemComponent;

// src/CarouselVideoItem/index.tsx
import { CarouselVideoItem } from "@achmea/ui";
import { jsx as jsx19 } from "react/jsx-runtime";
var CarouselVideoItemComponent = ({
  fields
}) => /* @__PURE__ */ jsx19(CarouselVideoItem, { fields });
var CarouselVideoItem_default = CarouselVideoItemComponent;

// src/ContactCards/index.tsx
import { ContactCardsBlock } from "@achmea/ui";
import { jsx as jsx20 } from "react/jsx-runtime";
var ContactCardsComponent = ({
  fields,
  rendering
}) => /* @__PURE__ */ jsx20(ContactCardsBlock, { fields, rendering });
var ContactCards_default = ContactCardsComponent;

// src/ContactCardsItem/index.tsx
import { ContactCardsItem } from "@achmea/ui";
import { jsx as jsx21 } from "react/jsx-runtime";
var ContactCardsItemComponent = ({
  fields
}) => /* @__PURE__ */ jsx21(ContactCardsItem, { fields });
var ContactCardsItem_default = ContactCardsItemComponent;

// src/ContactForm/index.tsx
import { ContactFormBlock } from "@achmea/features";
import { jsx as jsx22 } from "react/jsx-runtime";
var ContactFormComponent = ({ fields }) => /* @__PURE__ */ jsx22(ContactFormBlock, { fields });
var ContactForm_default = ContactFormComponent;

// src/ContactFormWithAttachments/index.tsx
import { ContactFormWithAttachmentsBlock } from "@achmea/features";
import { jsx as jsx23 } from "react/jsx-runtime";
var ContactFormWithAttachmentsComponent = ({
  fields
}) => /* @__PURE__ */ jsx23(ContactFormWithAttachmentsBlock, { fields });
var ContactFormWithAttachments_default = ContactFormWithAttachmentsComponent;

// src/DetailPageLayout/index.tsx
import { DetailPage } from "@achmea/ui";
import { jsx as jsx24 } from "react/jsx-runtime";
var DetailPageLayoutComponent = ({
  rendering
}) => /* @__PURE__ */ jsx24(DetailPage, { rendering });
var DetailPageLayout_default = DetailPageLayoutComponent;

// src/PoignantCasesForm/index.tsx
import { PoignantCasesFormBlock } from "@achmea/features";
import { jsx as jsx25 } from "react/jsx-runtime";
var PoignantCasesFormComponent = ({ fields }) => /* @__PURE__ */ jsx25(PoignantCasesFormBlock, { fields });
var PoignantCasesForm_default = PoignantCasesFormComponent;

// src/Hashtag/index.tsx
import { Hashtag } from "@achmea/ui";
import { jsx as jsx26 } from "react/jsx-runtime";
var HashtagComponent = ({ fields }) => /* @__PURE__ */ jsx26(Hashtag, { fields });
var Hashtag_default = HashtagComponent;

// src/Hero/index.tsx
import { HeroBlock } from "@achmea/ui";
import { jsx as jsx27 } from "react/jsx-runtime";
var HeroComponent = ({ fields }) => /* @__PURE__ */ jsx27(HeroBlock, { fields });
var Hero_default = HeroComponent;

// src/HeroArticle/index.tsx
import { HeroArticleBlock } from "@achmea/ui";
import { jsx as jsx28 } from "react/jsx-runtime";
var HeroArticleComponent = ({
  fields,
  rendering
}) => /* @__PURE__ */ jsx28(HeroArticleBlock, { fields, rendering });
var HeroArticle_default = HeroArticleComponent;

// src/HeroLinkContainer/index.tsx
import { HeroLinkContainer } from "@achmea/ui";
import { jsx as jsx29 } from "react/jsx-runtime";
var HeroLinkContainerComponent = ({
  rendering
}) => /* @__PURE__ */ jsx29(HeroLinkContainer, { rendering });
var HeroLinkContainer_default = HeroLinkContainerComponent;

// src/HeroLinkItem/index.tsx
import { HeroLinkItem } from "@achmea/ui";
import { jsx as jsx30 } from "react/jsx-runtime";
var HeroLinkItemComponent = ({ fields }) => /* @__PURE__ */ jsx30(HeroLinkItem, { fields });
var HeroLinkItem_default = HeroLinkItemComponent;

// src/HeroWithCarousel/index.tsx
import { HeroWithCarouselBlock } from "@achmea/ui";
import { jsx as jsx31 } from "react/jsx-runtime";
var HeroWithCarouselComponent = ({
  fields,
  rendering
}) => /* @__PURE__ */ jsx31(HeroWithCarouselBlock, { fields, rendering });
var HeroWithCarousel_default = HeroWithCarouselComponent;

// src/HeroWithCoverImage/index.tsx
import { HeroWithCoverImageBlock } from "@achmea/ui";
import { jsx as jsx32 } from "react/jsx-runtime";
var HeroWithCoverImageComponent = ({
  fields,
  rendering
}) => /* @__PURE__ */ jsx32(HeroWithCoverImageBlock, { fields, rendering });
var HeroWithCoverImage_default = HeroWithCoverImageComponent;

// src/HeroWithImage/index.tsx
import { HeroWithImageBlock } from "@achmea/ui";
import { jsx as jsx33 } from "react/jsx-runtime";
var HeroWithImageComponent = ({ fields }) => /* @__PURE__ */ jsx33(HeroWithImageBlock, { fields });
var HeroWithImage_default = HeroWithImageComponent;

// src/HeroWithLargeCardSlider/index.tsx
import { HeroWithLargeCardSliderBlock } from "@achmea/ui";
import { jsx as jsx34 } from "react/jsx-runtime";
var HeroWithLargeCardSliderComponent = ({
  fields,
  rendering
}) => /* @__PURE__ */ jsx34(HeroWithLargeCardSliderBlock, { fields, rendering });
var HeroWithLargeCardSlider_default = HeroWithLargeCardSliderComponent;

// src/HeroWithOverview/index.tsx
import { HeroWithOverviewBlock } from "@achmea/ui";
import { jsx as jsx35 } from "react/jsx-runtime";
var HeroWithOverviewComponent = ({
  fields,
  rendering
}) => /* @__PURE__ */ jsx35(HeroWithOverviewBlock, { fields, rendering });
var HeroWithOverview_default = HeroWithOverviewComponent;

// src/HeroWithQuickLinks/index.tsx
import { HeroWithQuickLinksBlock } from "@achmea/ui";
import { jsx as jsx36 } from "react/jsx-runtime";
var HeroWithQuickLinksComponent = ({
  fields,
  rendering
}) => /* @__PURE__ */ jsx36(HeroWithQuickLinksBlock, { fields, rendering });
var HeroWithQuickLinks_default = HeroWithQuickLinksComponent;

// src/HeroWithVideo/index.tsx
import { HeroWithVideoBlock } from "@achmea/ui";
import { jsx as jsx37 } from "react/jsx-runtime";
var HeroWithVideoComponent = ({ fields }) => /* @__PURE__ */ jsx37(HeroWithVideoBlock, { fields });
var HeroWithVideo_default = HeroWithVideoComponent;

// src/HeroWithEmbed/index.tsx
import { HeroWithEmbedBlock } from "@achmea/ui";
import { jsx as jsx38 } from "react/jsx-runtime";
var HeroWithEmbedComponent = ({ fields }) => /* @__PURE__ */ jsx38(HeroWithEmbedBlock, { fields });
var HeroWithEmbed_default = HeroWithEmbedComponent;

// src/Image/index.tsx
import { ImageBlock } from "@achmea/ui";
import { jsx as jsx39 } from "react/jsx-runtime";
var ImageComponent = ({ fields }) => /* @__PURE__ */ jsx39(ImageBlock, { fields });
var Image_default = ImageComponent;

// src/InsuranceSelector/index.tsx
import { InsuranceSelectorBlock } from "@achmea/ui";
import { jsx as jsx40 } from "react/jsx-runtime";
var InsuranceSelectorComponent = ({
  fields
}) => {
  return /* @__PURE__ */ jsx40(InsuranceSelectorBlock, { fields });
};
var InsuranceSelector_default = InsuranceSelectorComponent;

// src/InsurancesPageLayout/index.tsx
import { InsurancesPage } from "@achmea/ui";
import { jsx as jsx41 } from "react/jsx-runtime";
var InsurancesPageLayoutComponent = ({
  rendering
}) => /* @__PURE__ */ jsx41(InsurancesPage, { rendering });
var InsurancesPageLayout_default = InsurancesPageLayoutComponent;

// src/InteractiveMap/index.tsx
import { InteractiveMapBlock } from "@achmea/ui";
import { jsx as jsx42 } from "react/jsx-runtime";
var InteractiveMapComponent = ({ fields }) => /* @__PURE__ */ jsx42(InteractiveMapBlock, { fields });
var InteractiveMap_default = InteractiveMapComponent;

// src/InteractiveMapMarker/index.tsx
import { InteractiveMapMarker } from "@achmea/ui";
import { jsx as jsx43 } from "react/jsx-runtime";
var InteractiveMapMarkerComponent = ({
  fields
}) => /* @__PURE__ */ jsx43(InteractiveMapMarker, { fields });
var InteractiveMapMarker_default = InteractiveMapMarkerComponent;

// src/LargeCardSlider/index.tsx
import { LargeCardSliderBlock } from "@achmea/ui";
import { jsx as jsx44 } from "react/jsx-runtime";
var LargeCardSliderComponent = ({
  fields,
  rendering
}) => /* @__PURE__ */ jsx44(LargeCardSliderBlock, { fields, rendering });
var LargeCardSlider_default = LargeCardSliderComponent;

// src/LeadParagraph/index.tsx
import { LeadParagraphBlock } from "@achmea/ui";
import { jsx as jsx45 } from "react/jsx-runtime";
var LeadParagraphComponent = ({
  fields,
  rendering
}) => /* @__PURE__ */ jsx45(LeadParagraphBlock, { fields, rendering });
var LeadParagraph_default = LeadParagraphComponent;

// src/LinkList/index.tsx
import { LinkListBlock } from "@achmea/ui";
import { jsx as jsx46 } from "react/jsx-runtime";
var LinkListComponent = ({ fields, rendering }) => /* @__PURE__ */ jsx46(LinkListBlock, { fields, rendering });
var LinkList_default = LinkListComponent;

// src/LinkListItem/index.tsx
import { LinkListItem } from "@achmea/ui";
import { jsx as jsx47 } from "react/jsx-runtime";
var LinkListItemComponent = ({ fields }) => /* @__PURE__ */ jsx47(LinkListItem, { fields });
var LinkListItem_default = LinkListItemComponent;

// src/MultiImage/index.tsx
import { MultiImageBlock } from "@achmea/ui";
import { jsx as jsx48 } from "react/jsx-runtime";
var MultiImageComponent = ({ fields }) => /* @__PURE__ */ jsx48(MultiImageBlock, { fields });
var MultiImage_default = MultiImageComponent;

// src/OnsiteSubsitePageLayout/index.tsx
import { OnsiteSubsitePage } from "@achmea/ui";
import { jsx as jsx49 } from "react/jsx-runtime";
var OnsiteSubsitePageLayout = ({
  rendering
}) => /* @__PURE__ */ jsx49(OnsiteSubsitePage, { rendering });
var OnsiteSubsitePageLayout_default = OnsiteSubsitePageLayout;

// src/OverviewPageLayout/index.tsx
import { OverviewPage } from "@achmea/ui";
import { jsx as jsx50 } from "react/jsx-runtime";
var OverviewPageLayoutComponent = ({
  rendering
}) => /* @__PURE__ */ jsx50(OverviewPage, { rendering });
var OverviewPageLayout_default = OverviewPageLayoutComponent;

// src/Paragraph/index.tsx
import { ParagraphBlock } from "@achmea/ui";
import { jsx as jsx51 } from "react/jsx-runtime";
var Paragraph = ({ fields, rendering }) => /* @__PURE__ */ jsx51(ParagraphBlock, { fields, rendering });
var Paragraph_default = Paragraph;

// src/ParagraphWithImage/index.tsx
import { ParagraphWithImageBlock } from "@achmea/ui";
import { jsx as jsx52 } from "react/jsx-runtime";
var ParagraphWithImage = ({
  fields,
  rendering
}) => /* @__PURE__ */ jsx52(ParagraphWithImageBlock, { fields, rendering });
var ParagraphWithImage_default = ParagraphWithImage;

// src/ProjectOverview/index.tsx
import { ProjectOverviewBlock } from "@achmea/ui";
import { jsx as jsx53 } from "react/jsx-runtime";
var ProjectOverviewComponent = ({
  fields,
  rendering
}) => /* @__PURE__ */ jsx53(ProjectOverviewBlock, { fields, rendering });
var ProjectOverview_default = ProjectOverviewComponent;

// src/ProjectOverviewDetailsPageLayout/index.tsx
import { ProjectOverviewDetailsPage } from "@achmea/ui";
import { jsx as jsx54 } from "react/jsx-runtime";
var ProjectOverviewDetailsPageLayout = ({
  rendering
}) => /* @__PURE__ */ jsx54(ProjectOverviewDetailsPage, { rendering });
var ProjectOverviewDetailsPageLayout_default = ProjectOverviewDetailsPageLayout;

// src/ProjectOverviewPageLayout/index.tsx
import { ProjectOverviewPage } from "@achmea/ui";
import { jsx as jsx55 } from "react/jsx-runtime";
var ProjectOverviewPageLayout = ({
  rendering
}) => /* @__PURE__ */ jsx55(ProjectOverviewPage, { rendering });
var ProjectOverviewPageLayout_default = ProjectOverviewPageLayout;

// src/Publication/index.tsx
import { PublicationBlock } from "@achmea/features";
import { jsx as jsx56 } from "react/jsx-runtime";
var PublicationComponent = ({ fields }) => /* @__PURE__ */ jsx56(PublicationBlock, { fields });
var Publication_default = PublicationComponent;

// src/SearchPageLayout/index.tsx
import { SearchPage } from "@achmea/ui";
import { jsx as jsx57 } from "react/jsx-runtime";
var SearchPageLayoutComponent = ({
  rendering
}) => /* @__PURE__ */ jsx57(SearchPage, { rendering });
var SearchPageLayout_default = SearchPageLayoutComponent;

// src/QuickLinks/index.tsx
import { QuickLinksBlock } from "@achmea/ui";
import { jsx as jsx58 } from "react/jsx-runtime";
var QuickLinks = ({ fields, rendering }) => /* @__PURE__ */ jsx58(QuickLinksBlock, { fields, rendering });
var QuickLinks_default = QuickLinks;

// src/QuickLinksItem/index.tsx
import { QuickLinksItem } from "@achmea/ui";
import { jsx as jsx59 } from "react/jsx-runtime";
var QuickLinksItemComponent = ({ fields }) => /* @__PURE__ */ jsx59(QuickLinksItem, { fields });
var QuickLinksItem_default = QuickLinksItemComponent;

// src/Quote/index.tsx
import { QuoteBlock } from "@achmea/ui";
import { jsx as jsx60 } from "react/jsx-runtime";
var Quote = ({ fields }) => /* @__PURE__ */ jsx60(QuoteBlock, { fields });
var Quote_default = Quote;

// src/ReviewSlider/index.tsx
import { ReviewSliderBlock } from "@achmea/ui";
import { jsx as jsx61 } from "react/jsx-runtime";
var ReviewSlider = ({ fields, rendering }) => /* @__PURE__ */ jsx61(ReviewSliderBlock, { fields, rendering });
var ReviewSlider_default = ReviewSlider;

// src/ThemeOverviewPageLayout/index.tsx
import { ThemeOverviewPage } from "@achmea/ui";
import { jsx as jsx62 } from "react/jsx-runtime";
var ThemeOverviewPageLayoutComponent = ({
  rendering
}) => /* @__PURE__ */ jsx62(ThemeOverviewPage, { rendering });
var ThemeOverviewPageLayout_default = ThemeOverviewPageLayoutComponent;

// src/ThemePageLayout/index.tsx
import { ThemePage } from "@achmea/ui";
import { jsx as jsx63 } from "react/jsx-runtime";
var ThemePageLayoutComponent = ({
  rendering
}) => /* @__PURE__ */ jsx63(ThemePage, { rendering });
var ThemePageLayout_default = ThemePageLayoutComponent;

// src/Tiles/index.tsx
import { TilesBlock } from "@achmea/ui";
import { jsx as jsx64 } from "react/jsx-runtime";
var TilesComponent = ({ fields, rendering }) => {
  return /* @__PURE__ */ jsx64(TilesBlock, { fields, rendering });
};
var Tiles_default = TilesComponent;

// src/TilesItem/index.tsx
import { TilesItem } from "@achmea/ui";
import { jsx as jsx65 } from "react/jsx-runtime";
var TilesItemComponent = ({ fields }) => {
  return /* @__PURE__ */ jsx65(TilesItem, { fields });
};
var TilesItem_default = TilesItemComponent;

// src/TwoColumnArticleOverview/index.tsx
import { TwoColumnArticleOverviewBlock } from "@achmea/ui";
import { jsx as jsx66 } from "react/jsx-runtime";
var TwoColumnArticleOverviewComponent = ({
  fields,
  rendering
}) => {
  return /* @__PURE__ */ jsx66(TwoColumnArticleOverviewBlock, { fields, rendering });
};
var TwoColumnArticleOverview_default = TwoColumnArticleOverviewComponent;

// src/Video/index.tsx
import { VideoBlock } from "@achmea/ui";
import { jsx as jsx67 } from "react/jsx-runtime";
var VideoComponent = ({ fields }) => {
  return /* @__PURE__ */ jsx67(VideoBlock, { fields });
};
var Video_default = VideoComponent;

// src/index.ts
var components = /* @__PURE__ */ new Map();
components.set("Accordion", Accordion_default);
components.set("AccordionItem", AccordionItem_default);
components.set("AccordionWithImages", AccordionWithImages_default);
components.set("AccordionWithImagesItem", AccordionWithImagesItem_default);
components.set("AccordionWithSideImage", AccordionWithSideImage_default);
components.set("ArticleOverview", ArticleOverview_default);
components.set("ArticleOverviewAutomatic", ArticleOverviewAutomatic_default);
components.set("ArticleOverviewItem", ArticleOverviewItem_default);
components.set("ArticleOverviewPageLayout", ArticleOverviewPageLayout_default);
components.set("ArticlePageLayout", ArticlePageLayout_default);
components.set("Button", Button_default);
components.set("ButtonContainer", ButtonContainer_default);
components.set("CallToAction", CallToAction_default);
components.set("Card", Card_default);
components.set("CardSlider", CardSlider_default);
components.set("Carousel", Carousel_default);
components.set("CarouselCardItem", CarouselCardItem_default);
components.set("CarouselImageItem", CarouselImageItem_default);
components.set("CarouselVideoItem", CarouselVideoItem_default);
components.set("ContactCards", ContactCards_default);
components.set("ContactCardsItem", ContactCardsItem_default);
components.set("ContactForm", ContactForm_default);
components.set("ContactFormWithAttachments", ContactFormWithAttachments_default);
components.set("DetailPageLayout", DetailPageLayout_default);
components.set("PoignantCasesForm", PoignantCasesForm_default);
components.set("Hashtag", Hashtag_default);
components.set("Hero", Hero_default);
components.set("HeroArticle", HeroArticle_default);
components.set("HeroLinkContainer", HeroLinkContainer_default);
components.set("HeroLinkItem", HeroLinkItem_default);
components.set("HeroWithCarousel", HeroWithCarousel_default);
components.set("HeroWithCoverImage", HeroWithCoverImage_default);
components.set("HeroWithImage", HeroWithImage_default);
components.set("HeroWithLargeCardSlider", HeroWithLargeCardSlider_default);
components.set("HeroWithOverview", HeroWithOverview_default);
components.set("HeroWithQuicklinks", HeroWithQuickLinks_default);
components.set("HeroWithVideo", HeroWithVideo_default);
components.set("HeroWithEmbed", HeroWithEmbed_default);
components.set("Image", Image_default);
components.set("InsuranceSelector", InsuranceSelector_default);
components.set("InsurancesPageLayout", InsurancesPageLayout_default);
components.set("InteractiveMap", InteractiveMap_default);
components.set("InteractiveMapMarker", InteractiveMapMarker_default);
components.set("LargeCardSlider", LargeCardSlider_default);
components.set("LeadParagraph", LeadParagraph_default);
components.set("LinkList", LinkList_default);
components.set("LinkListItem", LinkListItem_default);
components.set("MultiImage", MultiImage_default);
components.set("OnsiteSubsitePageLayout", OnsiteSubsitePageLayout_default);
components.set("OverviewPageLayout", OverviewPageLayout_default);
components.set("Paragraph", Paragraph_default);
components.set("ParagraphWithImage", ParagraphWithImage_default);
components.set("ProjectOverview", ProjectOverview_default);
components.set("ProjectOverviewDetailsPageLayout", ProjectOverviewDetailsPageLayout_default);
components.set("ProjectOverviewPageLayout", ProjectOverviewPageLayout_default);
components.set("Publication", Publication_default);
components.set("SearchPageLayout", SearchPageLayout_default);
components.set("Quicklinks", QuickLinks_default);
components.set("QuicklinksItem", QuickLinksItem_default);
components.set("Quote", Quote_default);
components.set("ReviewSlider", ReviewSlider_default);
components.set("ThemeOverviewPageLayout", ThemeOverviewPageLayout_default);
components.set("ThemePageLayout", ThemePageLayout_default);
components.set("Tiles", Tiles_default);
components.set("TilesItem", TilesItem_default);
components.set("TwoColumnArticleOverview", TwoColumnArticleOverview_default);
components.set("Video", Video_default);
export {
  components
};
